import React, { Component } from "react";

import "../styles/home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: this.getContent("[<VideoTag>]"),
      text: this.getContent("[<TextTag>]"),
      img: this.getBackgroundImg(),
    };
    // console.log(this.state);
  }

  getBackgroundImg = () => {
    var el = document.createElement("html");
    el.innerHTML = this.props.page.body;
    var arr = [].slice.call(el.getElementsByTagName("img"));
    if (!arr[0]) return "http://arslansiddique.com/gatsby/home_bg.png";
    return arr[0].src;
  };
  getContent = (tag) => {
    var el = document.createElement("html");
    el.innerHTML = this.props.page.body;
    var arr = [].slice.call(el.getElementsByTagName("p"));

    for (var x = 0; x < arr.length; x++) {
      let element = arr[x];
      if (element.innerText.includes(tag)) {
        return element.innerText.split(tag)[1];
      }
    }
    return null;
  };

  render() {
    return (
      <div
        className="homepage"
        style={{
          background: !this.state.video
            ? `url(${this.state.img}) no-repeat scroll center center `
            : "",
          backgroundSize: "cover",
        }}
      >
        <div className="home_btm_txt">
          <h2>{this.state.text}</h2>
        </div>
        {this.state.video && (
          <video muted loop autoPlay playsInline>
            <source src={this.state.video} type="video/mp4" />
            {
              //player.vimeo.com/external/394970398.hd.mp4?s=6b12ea3ba90dc33e1e0256b9c627f2a543dcdd71&profile_id=175
            }
          </video>
        )}
      </div>
    );
  }
}
