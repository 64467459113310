import React, { Component } from "react";

import "../styles/instagram.css";

import compose from "recompose/compose";
function InstaGrid(props) {
  if (props.status == "completed")
    return (
      <div>
        <div className="instagram-page">
          {props.media.map((post) => {
            return (
              <div className="image" key={post.thumbnail}>
                <img src={post.thumbnail}></img>

                <a href={post.postLink} target="_blank">
                  <div className="overlay"></div>
                </a>
              </div>
            );
          })}
          
        </div>
        <footer><div className="credits">©2021 Committee Members</div></footer>
      </div>
    );
  else return <div className="instagram-page">Instagram Loading...</div>;
}

export default InstaGrid;
