import React, { Component, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "../styles/universal.css";

export default function UniversalMelody(props) {
  // let articles = props.page.articles.edges;
  let articles = props.page.articles.edges;
  const [articleNum, setArticleNum] = useState(0);

  const [numberStack, setNumberStack] = useState([]);

  // console.log(articles);

  const nextArticle = () => {
    console.log("NEXT");
    // randomize
    // if (numberStack.length != articles.length - 1) {
    let x = articleNum;

    numberStack.push(articleNum);
    setNumberStack(numberStack);

    // while (numberStack.includes(x)) {
    while (numberStack[numberStack.length - 1] == x) {
      x = Math.floor(Math.random() * articles.length);
    }
    // }

    setArticleNum(x);
    // }
    // else {
    // console.log("all articles viewed");
    // }
  };
  const prevArticle = () => {
    console.log("PREV");
    setArticleNum(numberStack.pop());
  };
  return (
    <TransitionGroup>
      <CSSTransition key={articleNum} classNames="universal-ani" timeout={200}>
        <div className="universal_page">
          <div className="universal_content">
            <div
              style={{ height: "100%" }}
              dangerouslySetInnerHTML={{
                __html: articles[articleNum].node.contentHtml,
              }}
            ></div>

            <div className="universal-controls" style={{ margin: "auto" }}>
              <a
                className={numberStack.length ? " " : " disabled"}
                onClick={prevArticle}
              >
                {"< Prev"}
              </a>
              <div style={{ width: "20px" }}></div>
              <a
                // className={
                //   numberStack.length != articles.length - 1 ? " " : " disabled"
                // }
                onClick={nextArticle}
              >
                {"Next >"}
              </a>
            </div>

            <div className="credits">©2021 Committee Members</div>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
