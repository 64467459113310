import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import Select from "react-dropdown-select";

import ThankYou from "./thankyou_page";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "../styles/contact.css";

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      fname: "",
      lname: "",
      email: "",
      subject: "",
      message: "",
      ordernumber: "",
      button_active: false,
      failed_check: false,
      thank_you: false,
    };
  }

  checkVars(vars) {
    if (vars.filter((x) => x).length == 5) return true;
    this.setState({ failed_check: true });
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { fname, lname, email, subject, message, ordernumber } = this.state;

    if (this.checkVars([fname, lname, email, subject, message])) {
      var self = this;
      this.setState({
        button_active: true,
        failed_check: false,
        thank_you: true,
      });
      setTimeout(function () {
        self.setState({
          button_active: false,
          thank_you: false,
        });
      }, 2000);

      let templateParams = {
        fname: fname,
        lname: lname,
        from_name: email,
        to_name: "Committee Members",
        subject: subject,
        message_html: message,
        ordernumber: ordernumber,
      };
      emailjs.send(
        "service_ii3v63p",
        "template_louvopb",
        templateParams,
        "user_afm3sRxrYix5FhbxTa2vv"
      );
      this.resetForm();
    }
  };

  resetForm() {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      // subject: "",
      message: "",
      ordernumber: "",
    });
  }

  handleChange = (param, e) => {
    if (param == "subject") {
      this.setState({ [param]: e[0].value });
    } else {
      this.setState({ [param]: e.target.value });
    }
  };

  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          key={this.state.thank_you}
          classNames="my-node"
          timeout={400}
        >
          {this.state.thank_you ? (
            <ThankYou type="contact" />
          ) : (
            <div>
              <div className="contact-page">
                <div className="contact-form">
                  <h1>Contact</h1>
                  <div className="names">
                    <div className="s_name">
                      <label className="name" htmlFor="fname">
                        First Name
                      </label>
                      <input
                        className="name"
                        type="text"
                        id="fname"
                        name="firstname"
                        placeholder={this.state.failed_check ? "required*" : ""}
                        value={this.state.fname}
                        onChange={(e) => this.handleChange("fname", e)}
                        required
                      ></input>
                    </div>

                    <div className="s_name">
                      <label className="name" htmlFor="lname">
                        Last Name
                      </label>
                      <input
                        className="name"
                        type="text"
                        id="lname"
                        name="lastname"
                        placeholder={this.state.failed_check ? "required*" : ""}
                        value={this.state.lname}
                        onChange={(e) => this.handleChange("lname", e)}
                      ></input>
                    </div>
                  </div>
                  <div className="names">
                    <div className="email_div">
                      <label htmlFor="email">E-mail Address</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder={this.state.failed_check ? "required*" : ""}
                        value={this.state.email}
                        onChange={(e) => this.handleChange("email", e)}
                      ></input>
                    </div>
                    <div className="order_div">
                      <label htmlFor="ordernumber">Order Number</label>
                      <input
                        type="text"
                        id="ordernumber"
                        name="ordernumber"
                        placeholder=""
                        value={this.state.ordernumber}
                        onChange={(e) => this.handleChange("ordernumber", e)}
                      ></input>
                    </div>
                  </div>
                  <div className="inputdiv">
                    <label htmlFor="subject">Subject</label>
                    <Select
                      options={[
                        { value: "Order Status", label: "Order Status" },
                        {
                          value: "General Inquires",
                          label: "General Inquires",
                        },
                        { value: "Press", label: "Press" },
                      ]}
                      onChange={(values) =>
                        this.handleChange("subject", values)
                      }
                    />
                  </div>
                  <div className="inputdiv">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="message"
                      id="message"
                      name="message"
                      placeholder={this.state.failed_check ? "required*" : ""}
                      value={this.state.message}
                      onChange={(e) => this.handleChange("message", e)}
                    ></textarea>
                  </div>
                  <div
                    className="flex row"
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      margin: 0,
                    }}
                  >
                    <button
                      className={
                        "submit-button clickable-button " +
                        (this.state.button_active ? "add-not-available " : " ")
                      }
                      type="submit"
                      value="Submit"
                      onClick={this.handleSubmit}
                    >
                      <p
                        style={{
                          transform: "scale(0.85, 1)",
                          transformOrigin: "bottom",
                        }}
                      >
                        {this.state.button_active ? "Submitted" : "Submit"}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="credits-stick">©2021 Committee Members</div>
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
export default ContactForm;
