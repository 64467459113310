import React, { Component, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "../styles/lightbox.css";
export default function LightboxOver(props) {
  const [photoIndex, setPhotoIndex] = useState(props.index);
  const images = props.images;

  return (
    <div>
      {props.isOpen && (
        <Lightbox
          animationDisabled={false}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => props.closeModal(photoIndex)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
  //   }
}
