import React, { Component } from "react";
import Modal from "react-modal";

import "../styles/popup.css";

export default function PopUp(props) {
  if (props.open) {
    document.body.style.position = "fixed";
  } else {
    document.body.style.position = "relative";
  }
  return (
    <div className="popup">
      <Modal
        id="popup"
        isOpen={props.open}
        onRequestClose={() => {
          props.setOpen(false);
        }}
        // style={{ width: "40%" }}
        // appElement=""
        contentLabel="Example Modal"
      >
        <button
          className="modal-close"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          x
        </button>
        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
      </Modal>
    </div>
  );
}
