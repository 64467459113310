import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PopUp from "../components/PopUp";

import "../styles/shop.css";

export default function Shop(props) {
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [modalData, setModalData] = React.useState(props.modals[0].node.body);
  // let cart_count = props.checkout.lineItems.edges.length;

  // function modalOpen(name) {
  //   console.log(modals[name].body);
  //   setModalData(modals[name].body);
  //   setIsOpen(true);
  // }
  // function extractModalData(modal_data) {
  //   let ret = {};
  //   modal_data.forEach((element) => {
  //     ret[element.node.title.replace("Modal/", "")] = element.node;
  //   });
  //   console.log(ret);
  //   return ret;
  // }
  // let modals = extractModalData(props.modals);
  console.log(props.collection.products);
  console.log(Math.ceil(props.collection.products.edges.length / 3));
  const [stick, setStick] = useState(
    window.innerHeight <
      1000 * Math.ceil(props.collection.products.edges.length / 3)
      ? "credits"
      : "credits-stick"
  );

  useEffect(() => {
    function handleResize() {
      window.innerHeight <
      1000 * Math.ceil(props.collection.products.edges.length / 3)
        ? setStick("credits")
        : setStick("credits-stick");
      console.log(stick);
    }

    window.addEventListener("resize", handleResize);
  });

  const [email, setEmail] = React.useState("");

  function getContent(tag) {
    var el = document.createElement("html");
    el.innerHTML = props.page.body;
    var arr = [].slice.call(el.getElementsByTagName("p"));
    for (var x = 0; x < arr.length; x++) {
      let element = arr[x];
      if (element.innerText.includes(tag)) {
        return element.innerText.split(tag)[1];
      }
    }
    return null;
  }
  let store_closed = getContent("[<StoreOff>]");

  if (store_closed)
    return (
      <div>
        <div className="mailing-page">
          <div className="mailing-content">
            <div className="mailing-paragraph-store-closed">{store_closed}</div>
            <div
              className="mailing-paragraph"
              dangerouslySetInnerHTML={{ __html: props.mailing_page.body }}
            ></div>

            <label htmlFor="email">E-mail Address</label>

            <div id="mc_embed_signup">
              <form
                action="https://committeemembers.us10.list-manage.com/subscribe/post?u=c8548a22997ad647898366a3d&amp;id=a0522ca91c"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                // novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="EMAIL"
                    class="email"
                    id="mce-EMAIL"
                    placeholder=""
                    required
                  ></input>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_c8548a22997ad647898366a3d_a0522ca91c"
                      tabindex="-1"
                      value=""
                    ></input>
                  </div>
                  <div class="clear">
                    <button
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="mailing-button"
                    >
                      <p
                        style={{
                          transform: "scale(0.85, 1)",
                          transformOrigin: "bottom",
                        }}
                      >
                        Welcome to the Committee
                      </p>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }} className={stick}>
          ©2021 Committee Members
        </div>
      </div>
    );
  else
    return (
      <div>
        <div
          className={
            "shop_page " +
            (props.collection.products.edges.length % 3 != 0
              ? "grid_page_odd"
              : "")
          }
        >
          {props.collection.products.edges.map((product) => {
            return (
              <div className="product-container" key={product.node.title}>
                <Link to={"/Shop/" + product.node.id.replace(/\s/g, "")}>
                  <div className="product">
                    <img src={product.node.images.edges[0].node.src}></img>
                    <div className="title">{product.node.title}</div>
                    <div className="price">
                      {product.node.variants.edges.filter(
                        (v) => v.node.available
                      ).length <= 0
                        ? "SOLD OUT"
                        : "$ " +
                          product.node.priceRange.minVariantPrice.amount.replace(
                            /\.0$/,
                            ""
                          ) +
                          " " +
                          product.node.priceRange.minVariantPrice.currencyCode}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: "30px" }} className={stick}>
          ©2021 Committee Members
        </div>
      </div>
    );
}
