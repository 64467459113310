import React, { Component } from "react";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
    console.log(this.props);
  }

  decrementQuantity(lineItemId) {
    this.props.updateLineItemInCart(
      lineItemId,
      this.props.line_item.quantity - 1
    );
  }

  incrementQuantity(lineItemId) {
    this.props.updateLineItemInCart(
      lineItemId,
      this.props.line_item.quantity + 1
    );
  }

  render() {
    return (
      // <tbody>
      //   <tr className="line-item">
      //     <td className="cart-img">
      // <div className="line-item-img">
      //   {this.props.line_item.variant.image ? (
      //     <img
      //       src={this.props.line_item.variant.image.src}
      //       alt={`${this.props.line_item.title} product shot`}
      //     />
      //   ) : null}
      // </div>
      //     </td>
      //     <td className="cart-product-name">
      //       <span>{this.props.line_item.title}</span>
      //       <span>Size: {this.props.line_item.variant.title}</span>
      //     </td>
      //     <td className="cart-quantity">{this.props.line_item.quantity}</td>
      //     <td className="cart-price">
      //       ${" "}
      //       {this.props.line_item.quantity * this.props.line_item.variant.price}
      //     </td>
      //   </tr>
      // </tbody>

      <li className="line-item">
        <div className="line-item-content">
          <div className="cart-img">
            <div className="line-item-img">
              {this.props.line_item.variant.image ? (
                <img
                  src={this.props.line_item.variant.image.src}
                  alt={`${this.props.line_item.title} product shot`}
                />
              ) : null}
            </div>
          </div>
          <div className="cart-product-name">
            <span>{this.props.line_item.title}</span>
            <span>Size: {this.props.line_item.variant.title}</span>
          </div>
          <div className="cart-quantity">{this.props.line_item.quantity}</div>
          <div className="cart-price">
            ${" "}
            {this.props.line_item.quantity * this.props.line_item.variant.price}{" "}
            CAD
          </div>
        </div>
        <div className="line-item-edit">
          {/* <button
            onClick={() =>
              this.props.removeLineItemInCart(this.props.line_item.id)
            }
          >
            Update
          </button> */}
          <button
            onClick={() =>
              this.props.removeLineItemInCart(this.props.line_item.id)
            }
          >
            Remove
          </button>
          <div className="cart-quantity cart-quantity-mobile">
            {this.props.line_item.quantity}
          </div>
        </div>
      </li>

      // <li className="Line-item">
      //   <div className="Line-item__img">
      //     {this.props.line_item.variant.image ? (
      //       <img
      //         src={this.props.line_item.variant.image.src}
      //         alt={`${this.props.line_item.title} product shot`}
      //       />
      //     ) : null}
      //   </div>
      //   <div className="Line-item__content">
      //     <div className="Line-item__content-row">
      //       <div className="Line-item__variant-title">
      //         {this.props.line_item.variant.title}
      //       </div>
      //       <span className="Line-item__title">
      //         {this.props.line_item.title}
      //       </span>
      //     </div>
      //     <div className="Line-item__content-row">
      //       <div className="Line-item__quantity-container">
      //         <button
      //           className="Line-item__quantity-update"
      //           onClick={() => this.decrementQuantity(this.props.line_item.id)}
      //         >
      //           -
      //         </button>
      //         <span className="Line-item__quantity">
      //           {this.props.line_item.quantity}
      //         </span>
      //         <button
      //           className="Line-item__quantity-update"
      //           onClick={() => this.incrementQuantity(this.props.line_item.id)}
      //         >
      //           +
      //         </button>
      //       </div>
      //       <span className="Line-item__price">
      //         ${" "}
      //         {(
      //           this.props.line_item.quantity *
      //           this.props.line_item.variant.price
      //         ).toFixed(2)}
      //       </span>
      //       <button
      //         className="Line-item__remove"
      // onClick={() =>
      //   this.props.removeLineItemInCart(this.props.line_item.id)
      // }
      //       >
      //         ×
      //       </button>
      //     </div>
      //   </div>
      // </li>
    );
  }
}

export default LineItem;
