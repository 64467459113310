import React, { Component } from "react";
import "../styles/about.css";

export default function About(props) {
  console.log(props.page);
  return (
    <div>
      <div className="about-page">
        <div
          className="about-content"
          dangerouslySetInnerHTML={{ __html: props.page.body }}
        />
      </div>
      <footer>
        <div className="credits-stick">©2021 Committee Members</div>
      </footer>
    </div>
  );
}
