import React from "react";
import { Link, useLocation } from "react-router-dom";
import PopUp from "../components/PopUp";
import { useSwipeable } from "react-swipeable";
import SideBar from "./Sidebar";

// import "../styles/home.css";
import "../styles/menu.css";
import LogoSvg from "./LogoSvg";

export default function Navigation(props) {
  let location = useLocation();
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      // setDimensions({
      //   height: window.innerHeight,
      //   width: window.innerWidth,
      setWidth(window.innerWidth);
      // });
    }

    window.addEventListener("resize", handleResize);
  });

  // For mobile page heights
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  console.log(location);
  if (
    location.pathname == "/" ||
    location.pathname.includes("/Looks") ||
    location.pathname.includes("/About") ||
    location.pathname.includes("/JoinUs")
  ) {
    console.log("NO SCROLL");
    document.body.style.overflow = "hidden";
  } else {
    console.log("YES SCROLL");
    document.body.style.overflow = "auto";
  }
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  if (location.pathname == "/UniversalMelody") {
    document.body.style.backgroundColor = "#2e2b2f";
  } else {
    document.body.style.backgroundColor = "white";
  }

  // Modal Stuff:
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(props.modals[0].node.body);
  let cart_count = props.checkout.lineItems.edges.length;

  function modalOpen(name) {
    console.log(modals[name].body);
    setModalData(modals[name].body);
    setIsOpen(true);
  }
  function extractModalData(modal_data) {
    let ret = {};
    modal_data.forEach((element) => {
      ret[element.node.title.replace("Modal/", "")] = element.node;
    });
    return ret;
  }
  let modals = extractModalData(props.modals);

  // Mobile menu:
  const [isOpen, setOpen] = React.useState(false);
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: () => setOpen(true),
  });
  const swipeOpenMenuStyles = {
    float: "left",
    position: "fixed",
    width: "33%",
    height: "100%",
    // border: "2px dashed gray"
  };

  return (
    <div id="Navigation">
      {width < 933 && (
        <>
          <ul
            className={
              // location.pathname == "/" ? "home_menu main_menu" : "main_menu"
              "main_menu"
            }
          >
            <li
              className="nav-option nav-active"
              onClick={() => {
                setOpen(true);
              }}
            >
              <a
                style={{ color: "#ff3211", cursor: "pointer" }}
                // style={{ color: location.pathname == "/" ? "white" : "red" }}
              >
                Index
              </a>
            </li>
          </ul>
          <div {...handlers} style={swipeOpenMenuStyles} />
          <SideBar
            isOpen={isOpen}
            onStateChange={(s) => setOpen(s.isOpen)}
            stateController={setOpen}
            pageWrapId={"page-wrap"}
            outerContainerId={"Navigation"}
            pages={props.pages}
            firstlook={props.firstlook}
            firstum={props.firstum}
            modals={modals}
            modalOpen={modalOpen}
          />
        </>
      )}

      {width > 933 && (
        <nav>
          <ul
            className={
              // location.pathname == "/" ? "home_menu main_menu" : "main_menu"
              "main_menu"
            }
          >
            {props.pages.map(function (page) {
              if (page == "Shop") {
                if (props.collections.length > 1) {
                  let cols = props.collections.map(function (collection) {
                    return (
                      <li key={collection.node.title}>
                        <Link
                          to={
                            "/Shop/" + collection.node.title.replace(/\s/g, "")
                          }
                        >
                          {collection.node.title}
                        </Link>
                      </li>
                    );
                  });
                  return (
                    <li
                      className={
                        "nav-option nav-dropdown " +
                        (location.pathname.includes(page) ||
                        location.pathname.includes("Cart")
                          ? "nav-active"
                          : "")
                      }
                      key={page}
                    >
                      <Link>{page}</Link>
                      <ul className="nav-collections">{cols}</ul>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={
                        "nav-option nav-dropdown " +
                        (location.pathname.includes(page) ||
                        location.pathname.includes("Cart")
                          ? "nav-active"
                          : "")
                      }
                      key={page}
                    >
                      <Link to="/Shop/All">{page}</Link>
                    </li>
                  );
                }
              }
              if (page == "Looks") {
                return (
                  <li
                    className={
                      "nav-option " +
                      (location.pathname.includes(page.replace(/\s/g, ""))
                        ? "nav-active"
                        : "")
                    }
                    key={page}
                  >
                    <Link
                      to={
                        "/" +
                        page.replace(/\s/g, "") +
                        "/" +
                        props.firstlook.node.title.replace(/\s/g, "")
                      }
                    >
                      {page}
                    </Link>
                  </li>
                );
              }
              if (page == "News") {
                return (
                  <li
                    className={
                      "nav-option " +
                      (location.pathname.includes(page.replace(/\s/g, "")) ||
                      location.pathname.includes("Article")
                        ? "nav-active"
                        : "")
                    }
                    key={page}
                  >
                    <Link to={"/" + page.replace(/\s/g, "")}>{page}</Link>
                  </li>
                );
              }
              // if (page == "Universal Melody") {
              //   return (
              //     <li
              //       className={
              //         "nav-option " +
              //         (location.pathname.includes(page.replace(/\s/g, ""))
              //           ? "nav-active"
              //           : "")
              //       }
              //       key={page}
              //     >
              //       <Link
              //         to={
              //           "/" +
              //           page.replace(/\s/g, "") +
              //           "/" +
              //           props.firstum.node.title.replace(/\s/g, "")
              //         }
              //       >
              //         {page}
              //       </Link>
              //     </li>
              //   );
              // }
              return (
                <li
                  className={
                    "nav-option " +
                    (location.pathname.includes(page.replace(/\s/g, ""))
                      ? "nav-active"
                      : "")
                  }
                  key={page}
                >
                  <Link to={"/" + page.replace(/\s/g, "")}>{page}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
      <div className="home_logo">
        <Link to="/">
          <LogoSvg
            color={
              location.pathname == "/" ||
              location.pathname.includes("UniversalMelody")
                ? "white"
                : "black"
            }
          />
        </Link>
      </div>
      <PopUp open={modalIsOpen} setOpen={setIsOpen} content={modalData} />
      <div className="shop-modals">
        {/* (location.pathname.includes("Shop") ||
          location.pathname.includes("Cart")) && */}
        {location.pathname != "/" && (
          <div className="shop-sub-modals">
            {Object.keys(modals).map((m) => {
              return (
                <button
                  key={m}
                  onClick={() => {
                    modalOpen(m);
                  }}
                >
                  {m}
                </button>
              );
            })}
          </div>
        )}
        {location.pathname != "/" && (
          <div
            className={
              "shop-cart-modal " +
              (location.pathname.includes("UniversalMelody")
                ? "white-writing"
                : " ")
            }
          >
            <Link to="/Cart">Cart({cart_count})</Link>
          </div>
        )}
      </div>
    </div>
  );
}
