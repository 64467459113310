import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import parse, { domToReact } from "html-react-parser";

import "../styles/article.css";
import "react-medium-image-zoom/dist/styles.css";

export default function Article(props) {
  let span_check = false;
  let video_check = false;

  function haveVideo(tag) {
    var el = document.createElement("html");
    el.innerHTML = props.article.contentHtml;
    var arr = [].slice.call(el.getElementsByTagName("iframe"));

    if (arr.length > 0) {
      return true;
    }
    return false;
  }
  let dom_elements = parse(props.article.contentHtml, {
    replace: (domNode) => {
      if (
        domNode.name == "p" &&
        domNode.children.length > 0 &&
        domNode.children[0].name == "iframe"
      ) {
        console.log(domNode);
        return (
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={domNode.children[0].attribs.src}
              allow="fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        );
      }
      // if (domNode.name == "iframe") {
      //   console.log(domNode);
      //   return (
      //     <div className="embed-responsive embed-responsive-16by9">
      //       <iframe
      //         className="embed-responsive-item"
      //         src={domNode.attribs.src}
      //       ></iframe>
      //     </div>
      //   );
      // }
      if (
        domNode.name == "span" &&
        domNode.children.length > 0 &&
        domNode.children[0].name == "img"
      ) {
        span_check = true;
        return (
          <Zoom>
            <img
              className={"article-content-img"}
              src={domNode.children[0].attribs.src}
            />
          </Zoom>
        );
      }
      if (
        domNode.name == "p" &&
        domNode.children.length > 0 &&
        domNode.children[0].name == "img"
      ) {
        span_check = true;
        return (
          <Zoom>
            <img
              className={"article-content-img"}
              src={domNode.children[0].attribs.src}
            />
          </Zoom>
        );
      }
      if (!span_check && domNode.name == "img") {
        return (
          <Zoom>
            <img className={"article-content-img"} src={domNode.attribs.src} />
          </Zoom>
        );
      }
    },
  });
  useEffect(() => {});

  // <div dangerouslySetInnerHTML={{ __html: props.article.contentHtml }} />
  return (
    <div className="article-page">
      <div
        className="article-content"
        width="100%"
        style={haveVideo() ? { width: "1000px" } : {}}
      >
        <div className="article-title">{props.article.title}</div>
        {dom_elements}
        <div style={{ marginTop: "30px" }} className="credits">
          ©2021 Committee Members
        </div>
      </div>
    </div>
  );
}
