import React, { Component } from "react";
import LineItem from "./LineItem";
import { Link } from "react-router-dom";
import "../styles/cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.openCheckout = this.openCheckout.bind(this);
  }

  openCheckout() {
    window.open(this.props.checkout.webUrl, "_self");
  }

  render() {
    let line_items = this.props.checkout.lineItems.edges.map((line_item) => {
      return (
        <LineItem
          removeLineItemInCart={this.props.removeLineItemInCart}
          updateLineItemInCart={this.props.updateLineItemInCart}
          key={line_item.node.id.toString()}
          line_item={line_item.node}
        />
      );
    });

    if (this.props.checkout.lineItems.edges.length)
      return (
        <div className="cart-page">
          <ul>
            <li className="cart-table-headers">
              <div className="cart-img"></div>
              <div className="cart-product-name">Product Name</div>
              <div className="cart-quantity">Quantity</div>
              <div className="cart-price">Unit Price</div>
            </li>
            {line_items}
          </ul>
          <footer className="cart-finale">
            <div className="cart-sub-finale">
              <div className="pricing">
                <div className="">Subtotal</div>
                <span>$ {this.props.checkout.subtotalPrice}</span>
              </div>
              <div
                className="flex row"
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  margin: 0,
                }}
              >
                <button className="checkout-button" onClick={this.openCheckout}>
                  <p
                    style={{
                      transform: "scale(0.85, 1)",
                      transformOrigin: "center",
                      margin: 0,
                    }}
                  >
                    Checkout
                  </p>
                </button>
              </div>
            </div>
          </footer>
        </div>
      );
    else
      return (
        <div className="empty-cart">
          Your shopping cart is empty.
          <Link to="/Shop/All">Return to shop</Link>
        </div>
      );
  }
}

export default Cart;
