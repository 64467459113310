import React, { Component } from "react";
import "../styles/about.css";
import { Link, useLocation } from "react-router-dom";

export default function About(props) {
  console.log(props.page);
  return (
    <div>
      <div className="about-page">
        <div className="about-content">
          <p>
            404 - Page Not Found
            <br /> <br />
            It seems you have strayed from the way, my friend.
            <br />
            <Link to="/" className="home-link">
              May you find your way back.
            </Link>
          </p>
        </div>
      </div>
      <footer>
        <div className="credits-stick">©2021 Committee Members</div>
      </footer>
    </div>
  );
}
