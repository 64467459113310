import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LightboxOver from "../components/Lightbox";
// import Zoom from "react-medium-image-zoom";
import PopUp from "../components/PopUp";
import ReactTooltip from "react-tooltip";
// import VariantSelector from "./VariantSelector";

// import "react-medium-image-zoom/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/product.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "45%" }}
      onClick={onClick}
    >
      <button>
        <img
          className="arrow-img"
          src="/img/next.png"
          alt="my image"
          onClick={onClick}
        />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "45%" }}
      onClick={onClick}
    >
      <button>
        <img
          className="arrow-img"
          src="/img/prev.png"
          alt="my image"
          onClick={onClick}
        />
      </button>
    </div>
  );
}

class Product extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    this.state = {
      selectedOptions: {},
      price: this.props.product.priceRange.minVariantPrice.amount,
      variant: "",
      id: "",
      cart_count: this.props.checkout.lineItems.edges.length,
      button_active: false,
      lightboxOpen: false,
      photoIndex: 0,
      imageSrcs: this.props.product.images.edges.map((e) => {
        return e.node.src;
      }),
      available:
        this.props.product.variants.edges.filter((v) => v.node.available)
          .length > 0,
    };
  }

  changeVariant(variant) {
    if (variant.available)
      this.setState({
        price: variant.price,
        variant: variant.title,
        id: variant.id,
      });
  }

  addToCart = () => {
    var self = this;
    if (this.state.variant) {
      this.setState({
        cart_count: this.state.cart_count + 1,
        button_active: true,
      });
      setTimeout(function () {
        self.setState({
          button_active: false,
        });
      }, 1000);
      console.log(typeof this.state.id);
      this.props.addVariantToCart(this.state.id, 1);
    }
  };

  openLightbox = (e, idx) => {
    this.setState({
      lightboxOpen: true,
      photoIndex: idx,
    });
  };
  closeLightboxAndSet = (idx) => {
    this.setState({
      lightboxOpen: false,
    });
    // slider.slickGoTo(idx);
  };

  render() {
    return (
      <div>
        <div className="product-page">
          {this.state.lightboxOpen && (
            <LightboxOver
              isOpen={this.state.lightboxOpen}
              images={this.state.imageSrcs}
              closeModal={this.closeLightboxAndSet}
              index={this.state.photoIndex}
            />
          )}
          <ReactTooltip />
          <div className="product-images">
            {this.props.product.images.edges.map((image, idx) => {
              return (
                <img
                  key={image.node.src}
                  className="product-image"
                  src={image.node.src}
                  onClick={(e) => this.openLightbox(e, idx)}
                ></img>
              );
            })}
          </div>
          <div className="product-images-carousel">
            <Slider {...this.settings}>
              {this.props.product.images.edges.map((image, idx) => {
                return (
                  <div key={image.node.src}>
                    <img
                      src={image.node.src}
                      onClick={(e) => this.openLightbox(e, idx)}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="product-info">
            <div className="product-title">{this.props.product.title}</div>
            <div className="product-price">
              {"$" +
                this.state.price.replace(/\.0$/, "").replace(/\.00$/, "") +
                " " +
                this.props.product.priceRange.minVariantPrice.currencyCode}
            </div>
            <div
              className="product-description"
              dangerouslySetInnerHTML={{
                __html: this.props.product.descriptionHtml,
              }}
            >
              {
                // this.props.product.description
              }
            </div>
            <div className="size-buttons">
              {this.props.product.variants.edges.map((variant) => {
                return (
                  <button
                    className={
                      "size-button " +
                      (this.state.variant == variant.node.title
                        ? "selected "
                        : " ") +
                      (variant.node.available ? "" : "not-available")
                    }
                    //
                    key={variant.node.title}
                    onClick={() => {
                      this.changeVariant(variant.node);
                    }}
                  >
                    {variant.node.title}
                  </button>
                );
              })}
            </div>
            <button
              data-tip={
                this.state.variant || !this.state.available
                  ? ""
                  : "Must choose a size before adding"
              }
              className={
                "add-to-cart " +
                (this.state.variant ? "clickable clickable-button " : " ") +
                (this.state.button_active ? "add-not-available " : " ")
              }
              onClick={this.addToCart}
            >
              <p
                style={{
                  transform: "scale(0.85, 1)",
                  transformOrigin: "bottom",
                }}
              >
                {!this.state.available
                  ? "SOLD OUT"
                  : this.state.button_active
                  ? "Added to Cart"
                  : "Add to Cart"}
              </p>
            </button>
          </div>
        </div>
        <div className="credits">©2021 Committee Members</div>
      </div>
    );
  }
}

export default Product;
