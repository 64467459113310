import React, { useState } from "react";
import Slider from "react-slick";
import LightboxOver from "../components/Lightbox";

import "../styles/looks.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "45%" }}
      onClick={onClick}
    >
      <button>
        <img
          className="arrow-img"
          src="/img/next.png"
          alt="my image"
          onClick={onClick}
        />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", top: "45%" }}
      onClick={onClick}
    >
      <button>
        <img
          className="arrow-img"
          src="/img/prev.png"
          alt="my image"
          onClick={onClick}
        />
      </button>
    </div>
  );
}

export default function Look(props) {
  let product_names = props.products.map((p) => {
    return p.node.title;
  });
  const [width, setWidth] = React.useState(window.innerWidth);

  const extractImgs = (str) => {
    var el = document.createElement("html");
    el.innerHTML = str;
    var arr = [].slice.call(el.getElementsByTagName("img"));
    return arr.map((e) => {
      return e.src;
    });
  };
  const extractDescriptions = (str) => {
    var el = document.createElement("html");
    el.innerHTML = str;
    var arr = [].slice.call(el.getElementsByTagName("ul"));
    var ret = [];
    arr.forEach((e) => {
      ret.push(
        [].slice.call(e.children).map((li) => {
          return li.innerText;
        })
      );
    });
    if (ret.length == 0) return [[0], [0]];
    return ret;
  };
  const addDescriptionLinks = (descript) => {
    let ret = [];
    descript.forEach((d) => {
      d = d.replace(/(\r\n|\n|\r)/gm, "");
      if (product_names.includes(d)) {
        let product = props.products.find((p) => p.node.title == d); //GET PRo
        ret.push(
          <Link key={d} to={"/Shop/" + product.node.id.replace(/\s/g, "")}>
            <li>{d}</li>
          </Link>
        );
      } else {
        ret.push(<li key={d}>{d}</li>);
      }
    });
    return ret;
  };

  var imgs = extractImgs(props.article.contentHtml);
  // var descriptions = props.article.content.split("[Look]");
  var descriptions = extractDescriptions(props.article.contentHtml);
  const [description, setDescription] = useState(
    addDescriptionLinks(descriptions[0])
  );
  const [index, setIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [slider, setSlider] = useState(null);
  React.useEffect(() => {
    function handleResize() {
      // setWidth(0);
      setWidth(window.innerWidth);
      console.log(slider);
      // if (slider) slider;
    }

    window.addEventListener("resize", handleResize);
  });

  const onChange = (e) => {
    setDescription(addDescriptionLinks(descriptions[e]));
    setIndex(e);
  };

  let archives = props.looks.map(function (look) {
    return (
      <li key={look.node.title}>
        <Link to={"/Looks/" + look.node.title.replace(/\s/g, "")}>
          {look.node.title}
        </Link>
      </li>
    );
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prevIndex, nextIndex) => {
      onChange(nextIndex);
    },
  };

  const closeLightboxAndSet = (idx) => {
    setLightboxOpen(false);
    slider.slickGoTo(idx);
  };

  return (
    <div>
      <div className="looks-page">
        {lightboxOpen && (
          <LightboxOver
            isOpen={lightboxOpen}
            images={imgs}
            closeModal={closeLightboxAndSet}
            index={index}
          />
        )}
        <div className="looks-content">
          <div className="looks-carousel">
            {width && (
              <Slider
                style={{ width: "100%" }}
                ref={(r) => setSlider(r)}
                {...settings}
              >
                {imgs.map((i) => {
                  return <img src={i} onClick={() => setLightboxOpen(true)} />;
                })}
              </Slider>
            )}
          </div>
          <div className="looks-details">
            <div className="looks-detailing">
              <div className="looks-title">{props.article.title}</div>
              <div className="looks-description">
                <ul>{description}</ul>
              </div>
            </div>
            {archives.length > 1 && (
              <div className="looks-archive">
                Archive<ul className="looks-archive-list">{archives}</ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="credits-stick">©2021 Committee Members</div>
    </div>
  );
}
