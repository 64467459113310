import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../styles/news.css";

export default function News(props) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let articles = props.page.articles.edges.map((article) => {
    let d = new Date(article.node.publishedAt);
    let date =
      monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    return (
      <div className="product-container" key={article.node.id}>
        <Link to={"/Article/" + article.node.title.replace(/\s/g, "")}>
          <div className="news-article">
            <img src={article.node.image.src}></img>
            <div className="news-article-title">{article.node.title}</div>
            <div className="news-article-date">{date}</div>
          </div>
        </Link>
      </div>
    );
  });
  const [stick, setStick] = useState(
    window.innerHeight < 1000 * Math.ceil(articles.length / 3)
      ? "credits"
      : "credits-stick"
  );

  useEffect(() => {
    function handleResize() {
      window.innerHeight < 1000 * Math.ceil(articles.length / 3)
        ? setStick("credits")
        : setStick("credits-stick");
    }

    window.addEventListener("resize", handleResize);
  });
  return (
    <div>
      <div
        className={
          "news_page " + (articles.length % 3 != 0 ? "grid_page_odd" : "")
        }
      >
        {articles}
      </div>
      <div style={{ marginTop: "30px" }} className={stick}>
        ©2021 Committee Members
      </div>
    </div>
  );
}
