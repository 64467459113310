import React from "react";

export default function LogoSvg(props) {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 169.37 34.37"
        enableBackground="new 0 0 169.37 34.37;"
        space="preserve"
        style={{ width: "140px", fill: props.color }}
      >
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M13.77,10.08h6.79c-0.5,2.56-1.65,4.34-3.46,5.32c-1.44,0.77-3.63,1.15-6.56,1.15c-2.86,0-5.06-0.37-6.58-1.11
					c-2.56-1.25-3.85-3.6-3.85-7.05c0-3.45,1.28-5.8,3.85-7.05c1.52-0.74,3.72-1.11,6.58-1.11c2.58,0,4.6,0.33,6.07,0.98
					c2.29,1.03,3.61,2.87,3.95,5.53h-6.79c-0.28-1.78-1.36-2.67-3.23-2.67c-2.68,0-4.02,1.44-4.02,4.32c0,2.88,1.24,4.32,3.72,4.32
					C12.12,12.7,13.3,11.83,13.77,10.08z"
                />
                <path
                  d="M21.66,8.39c0-3.45,1.28-5.8,3.85-7.05c1.52-0.74,3.72-1.11,6.58-1.11c2.86,0,5.06,0.37,6.58,1.11
					c2.56,1.25,3.85,3.6,3.85,7.05c0,3.45-1.28,5.8-3.85,7.05c-1.52,0.74-3.72,1.11-6.58,1.11c-2.86,0-5.06-0.37-6.58-1.11
					C22.94,14.19,21.66,11.84,21.66,8.39z M28.07,8.39c0,2.88,1.34,4.32,4.02,4.32c2.68,0,4.02-1.44,4.02-4.32
					c0-2.88-1.34-4.32-4.02-4.32C29.41,4.07,28.07,5.51,28.07,8.39z"
                />
                <path d="M43.78,16.02V0.76h8.28l2.5,8.87h0.04l2.5-8.87h8.26v15.25h-5.24V5.12l-3.23,10.9h-4.61l-3.23-10.9v10.9H43.78z" />
                <path d="M67.18,16.02V0.76h8.48l2.5,8.87h0.04l2.5-8.87h8.48v15.25h-5.47V5.12l-3.23,10.9h-4.61l-3.23-10.9v10.9H67.18z" />
                <path d="M90.91,16.02V0.76h5.63v15.25H90.91z" />
                <path d="M103.85,16.02V4.61h-5.58V0.76h16.98v3.85h-5.37v11.41H103.85z" />
                <path d="M122.2,16.02V4.61h-5.41V0.76h17.01v3.85h-5.58v11.41H122.2z" />
                <path d="M135.43,16.02V0.76h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H135.43z" />
                <path d="M152.96,16.02V0.76h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H152.96z" />
              </g>
              <g>
                <path d="M13.48,33.63V18.38h8.48l2.5,8.87h0.04l2.5-8.87h8.48v15.25h-5.47v-10.9l-3.23,10.9h-4.61l-3.23-10.9v10.9H13.48z" />
                <path d="M37.17,33.63V18.38h16.22v3.72H43.2v2.05h9.46v3.72H43.2v2.05h10.19v3.72H37.17z" />
                <path d="M77.07,25.09v8.55H71.6v-10.9l-3.23,10.9h-4.61l-3.23-10.9v10.9h-5.47V18.38h8.48l2.5,8.87h0.04l2.5-8.87h8.48V25.09" />
                <path
                  d="M78.92,33.63V18.38h13.72c3.01,0,4.51,1.27,4.51,3.8c0,0.68-0.16,1.29-0.48,1.83c-0.32,0.53-0.77,0.92-1.33,1.14v0.04
					c0.88,0.23,1.57,0.73,2.05,1.52c0.37,0.61,0.56,1.27,0.56,1.97c0,3.3-1.99,4.96-5.96,4.96H78.92z M84.81,22.09v1.97h4.55
					c1,0,1.5-0.33,1.5-1c0-0.64-0.5-0.96-1.5-0.96H84.81z M84.81,27.52v2.39h5.15c1.04,0,1.56-0.4,1.56-1.2c0-0.8-0.52-1.2-1.56-1.2
					H84.81z"
                />
                <path d="M99.28,33.63V18.38h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H99.28z" />
                <path
                  d="M117.12,33.63V18.38h12.69c1.65,0,2.86,0.19,3.61,0.56c0.58,0.28,1.07,0.77,1.47,1.45c0.4,0.68,0.6,1.43,0.6,2.24
					c0,1.87-0.78,3.03-2.35,3.48v0.04c1.05,0.3,1.63,1.08,1.73,2.35l0.26,3.33c0.04,0.64,0.29,1.24,0.75,1.79h-6.58
					c-0.11-0.24-0.22-1.2-0.32-2.88c-0.07-1.21-0.59-1.82-1.56-1.82h-4.27v4.7H117.12z M123.14,22.22v2.86h4.27
					c1.11,0,1.67-0.48,1.67-1.43c0-0.95-0.58-1.43-1.75-1.43H123.14z"
                />
                <path
                  d="M147.54,28.18l-6.88-1.03c-2.78-0.41-4.17-1.84-4.17-4.29c0-3.35,2.94-5.02,8.82-5.02c3.32,0,5.78,0.43,7.37,1.28
					c1.51,0.81,2.32,2.05,2.44,3.72h-7.01c0-1.03-0.93-1.54-2.8-1.54c-1.61,0-2.41,0.36-2.41,1.09c0,0.46,0.26,0.72,0.79,0.79
					l7.2,1.07c3.28,0.48,4.91,1.97,4.91,4.44c0,1.87-0.79,3.24-2.37,4.13c-1.58,0.89-4.06,1.34-7.44,1.34
					c-2.89,0-5.08-0.29-6.58-0.88c-2.08-0.81-3.13-2.23-3.14-4.25h7.26c0.03,0.61,0.35,1.03,0.96,1.24
					c0.37,0.11,0.95,0.17,1.73,0.17c1.59,0,2.39-0.41,2.39-1.24C148.63,28.64,148.27,28.3,147.54,28.18z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              d="M157.04,29.89c0.17-0.37,0.39-0.69,0.67-0.97c0.28-0.27,0.61-0.49,0.98-0.64c0.37-0.15,0.77-0.23,1.19-0.23
			s0.82,0.08,1.19,0.23c0.37,0.15,0.7,0.37,0.97,0.64c0.28,0.27,0.5,0.59,0.66,0.97c0.16,0.37,0.25,0.77,0.25,1.2
			c0,0.44-0.08,0.85-0.25,1.22c-0.16,0.38-0.38,0.7-0.66,0.98c-0.28,0.27-0.6,0.49-0.97,0.64c-0.37,0.15-0.77,0.23-1.19,0.23
			s-0.82-0.08-1.19-0.23c-0.37-0.15-0.7-0.37-0.98-0.64c-0.28-0.27-0.5-0.6-0.67-0.98c-0.16-0.37-0.25-0.78-0.25-1.22
			C156.8,30.66,156.88,30.27,157.04,29.89z M157.54,32.14c0.13,0.32,0.31,0.6,0.53,0.83c0.23,0.23,0.49,0.42,0.8,0.55
			c0.31,0.13,0.64,0.2,1,0.2c0.35,0,0.68-0.07,0.99-0.2c0.31-0.13,0.57-0.32,0.8-0.55c0.23-0.23,0.4-0.51,0.53-0.83
			c0.13-0.32,0.19-0.67,0.19-1.04c0-0.36-0.06-0.71-0.19-1.02c-0.13-0.32-0.31-0.59-0.53-0.82c-0.23-0.23-0.49-0.41-0.8-0.55
			c-0.31-0.14-0.64-0.2-0.99-0.2c-0.36,0-0.7,0.07-1,0.2c-0.31,0.13-0.58,0.31-0.8,0.55s-0.4,0.51-0.53,0.82
			c-0.13,0.32-0.19,0.66-0.19,1.02C157.35,31.47,157.42,31.82,157.54,32.14z M160.07,29.32c0.43,0,0.74,0.08,0.95,0.25
			c0.2,0.17,0.31,0.43,0.31,0.77c0,0.32-0.09,0.56-0.27,0.71c-0.18,0.15-0.4,0.24-0.67,0.27l1.02,1.58h-0.6l-0.97-1.54h-0.59v1.54
			h-0.56v-3.58H160.07z M159.83,30.91c0.13,0,0.24,0,0.35-0.01c0.11-0.01,0.21-0.03,0.3-0.07c0.09-0.04,0.16-0.1,0.21-0.18
			c0.05-0.08,0.08-0.19,0.08-0.33c0-0.12-0.02-0.22-0.07-0.29c-0.05-0.07-0.11-0.13-0.18-0.17c-0.07-0.04-0.16-0.06-0.25-0.08
			c-0.1-0.01-0.19-0.02-0.28-0.02h-0.74v1.14H159.83z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className="st0"
                  d="M112.75-97.19h6.79c-0.5,2.56-1.65,4.34-3.46,5.32c-1.44,0.77-3.63,1.15-6.56,1.15
					c-2.86,0-5.06-0.37-6.58-1.11c-2.56-1.25-3.85-3.6-3.85-7.05c0-3.45,1.28-5.8,3.85-7.05c1.52-0.74,3.72-1.11,6.58-1.11
					c2.58,0,4.6,0.33,6.07,0.98c2.29,1.03,3.61,2.87,3.95,5.53h-6.79c-0.28-1.78-1.36-2.67-3.23-2.67c-2.68,0-4.02,1.44-4.02,4.32
					c0,2.88,1.24,4.32,3.72,4.32C111.11-94.56,112.28-95.43,112.75-97.19z"
                />
                <path
                  className="st0"
                  d="M120.65-98.87c0-3.45,1.28-5.8,3.85-7.05c1.52-0.74,3.72-1.11,6.58-1.11c2.86,0,5.06,0.37,6.58,1.11
					c2.56,1.25,3.85,3.6,3.85,7.05c0,3.45-1.28,5.8-3.85,7.05c-1.52,0.74-3.72,1.11-6.58,1.11c-2.86,0-5.06-0.37-6.58-1.11
					C121.93-93.08,120.65-95.43,120.65-98.87z M127.06-98.87c0,2.88,1.34,4.32,4.02,4.32c2.68,0,4.02-1.44,4.02-4.32
					c0-2.88-1.34-4.32-4.02-4.32C128.39-103.19,127.06-101.75,127.06-98.87z"
                />
                <path
                  className="st0"
                  d="M142.77-91.25v-15.25h8.28l2.5,8.87h0.04l2.5-8.87h8.26v15.25h-5.24v-10.9l-3.23,10.9h-4.61l-3.23-10.9
					v10.9H142.77z"
                />
                <path
                  className="st0"
                  d="M166.17-91.25v-15.25h8.48l2.5,8.87h0.04l2.5-8.87h8.48v15.25h-5.47v-10.9l-3.23,10.9h-4.61l-3.23-10.9
					v10.9H166.17z"
                />
                <path
                  className="st0"
                  d="M189.9-91.25v-15.25h5.63v15.25H189.9z"
                />
                <path
                  className="st0"
                  d="M202.84-91.25v-11.41h-5.58v-3.85h16.98v3.85h-5.37v11.41H202.84z"
                />
                <path
                  className="st0"
                  d="M221.18-91.25v-11.41h-5.41v-3.85h17.01v3.85h-5.58v11.41H221.18z"
                />
                <path
                  className="st0"
                  d="M234.42-91.25v-15.25h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H234.42z"
                />
                <path
                  className="st0"
                  d="M251.95-91.25v-15.25h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H251.95z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M112.47-73.63v-15.25h8.48l2.5,8.87h0.04l2.5-8.87h8.48v15.25H129v-10.9l-3.23,10.9h-4.61l-3.23-10.9v10.9
					H112.47z"
                />
                <path
                  className="st0"
                  d="M136.16-73.63v-15.25h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H136.16z"
                />
                <path
                  className="st0"
                  d="M176.06-82.18v8.55h-5.47v-10.9l-3.23,10.9h-4.61l-3.23-10.9v10.9h-5.47v-15.25h8.48l2.5,8.87h0.04
					l2.5-8.87h8.48V-82.18"
                />
                <path
                  className="st0"
                  d="M177.9-73.63v-15.25h13.72c3.01,0,4.51,1.27,4.51,3.8c0,0.68-0.16,1.29-0.48,1.83
					c-0.32,0.53-0.77,0.92-1.33,1.14v0.04c0.88,0.23,1.57,0.73,2.05,1.52c0.37,0.61,0.56,1.27,0.56,1.97c0,3.3-1.99,4.96-5.96,4.96
					H177.9z M183.8-85.17v1.97h4.55c1,0,1.5-0.33,1.5-1c0-0.64-0.5-0.96-1.5-0.96H183.8z M183.8-79.74v2.39h5.15
					c1.04,0,1.56-0.4,1.56-1.2s-0.52-1.2-1.56-1.2H183.8z"
                />
                <path
                  className="st0"
                  d="M198.27-73.63v-15.25h16.22v3.72h-10.19v2.05h9.46v3.72h-9.46v2.05h10.19v3.72H198.27z"
                />
                <path
                  className="st0"
                  d="M216.1-73.63v-15.25h12.69c1.65,0,2.86,0.19,3.61,0.56c0.58,0.28,1.07,0.77,1.47,1.45
					c0.4,0.68,0.6,1.43,0.6,2.24c0,1.87-0.78,3.03-2.35,3.48v0.04c1.05,0.3,1.63,1.08,1.73,2.35l0.26,3.33
					c0.04,0.64,0.29,1.24,0.75,1.79h-6.58c-0.11-0.24-0.22-1.2-0.32-2.88c-0.07-1.21-0.59-1.82-1.56-1.82h-4.27v4.7H216.1z
					 M222.13-85.04v2.86h4.27c1.11,0,1.67-0.48,1.67-1.43s-0.58-1.43-1.75-1.43H222.13z"
                />
                <path
                  className="st0"
                  d="M246.52-79.08l-6.88-1.03c-2.78-0.41-4.17-1.84-4.17-4.29c0-3.35,2.94-5.02,8.82-5.02
					c3.32,0,5.78,0.43,7.37,1.28c1.51,0.81,2.32,2.05,2.44,3.72h-7.01c0-1.03-0.93-1.54-2.8-1.54c-1.61,0-2.41,0.36-2.41,1.09
					c0,0.46,0.26,0.72,0.79,0.79l7.2,1.07c3.28,0.48,4.91,1.97,4.91,4.44c0,1.87-0.79,3.24-2.37,4.13s-4.06,1.34-7.44,1.34
					c-2.89,0-5.08-0.29-6.58-0.88c-2.08-0.81-3.13-2.23-3.14-4.25h7.26c0.03,0.61,0.35,1.03,0.96,1.24
					c0.37,0.11,0.95,0.17,1.73,0.17c1.59,0,2.39-0.41,2.39-1.24C247.61-78.62,247.25-78.97,246.52-79.08z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st0"
              d="M256.02-77.37c0.17-0.37,0.39-0.69,0.67-0.97c0.28-0.27,0.61-0.49,0.98-0.64s0.77-0.23,1.19-0.23
			s0.82,0.08,1.19,0.23c0.37,0.15,0.7,0.37,0.97,0.64c0.28,0.27,0.5,0.59,0.66,0.97c0.16,0.37,0.25,0.77,0.25,1.2
			c0,0.44-0.08,0.85-0.25,1.22c-0.16,0.38-0.38,0.7-0.66,0.98c-0.28,0.27-0.6,0.49-0.97,0.64c-0.37,0.15-0.77,0.23-1.19,0.23
			s-0.82-0.08-1.19-0.23c-0.37-0.15-0.7-0.37-0.98-0.64c-0.28-0.27-0.5-0.6-0.67-0.98c-0.16-0.37-0.25-0.78-0.25-1.22
			C255.78-76.6,255.86-77,256.02-77.37z M256.53-75.12c0.13,0.32,0.31,0.6,0.53,0.83c0.23,0.23,0.49,0.42,0.8,0.55
			c0.31,0.13,0.64,0.2,1,0.2c0.35,0,0.68-0.07,0.99-0.2s0.57-0.32,0.8-0.55c0.23-0.23,0.4-0.51,0.53-0.83
			c0.13-0.32,0.19-0.67,0.19-1.04c0-0.36-0.06-0.71-0.19-1.02c-0.13-0.32-0.31-0.59-0.53-0.82c-0.23-0.23-0.49-0.41-0.8-0.55
			c-0.31-0.14-0.64-0.2-0.99-0.2c-0.36,0-0.7,0.07-1,0.2c-0.31,0.13-0.58,0.31-0.8,0.55c-0.23,0.23-0.4,0.51-0.53,0.82
			c-0.13,0.32-0.19,0.66-0.19,1.02C256.34-75.79,256.4-75.44,256.53-75.12z M259.05-77.94c0.43,0,0.74,0.08,0.95,0.25
			c0.2,0.17,0.31,0.43,0.31,0.77c0,0.32-0.09,0.56-0.27,0.71c-0.18,0.15-0.4,0.24-0.67,0.27l1.02,1.58h-0.6l-0.97-1.54h-0.59v1.54
			h-0.56v-3.58H259.05z M258.81-76.36c0.13,0,0.24,0,0.35-0.01c0.11-0.01,0.21-0.03,0.3-0.07c0.09-0.04,0.16-0.1,0.21-0.18
			c0.05-0.08,0.08-0.19,0.08-0.33c0-0.12-0.02-0.22-0.07-0.29c-0.05-0.07-0.11-0.13-0.18-0.17c-0.07-0.04-0.16-0.06-0.25-0.08
			c-0.1-0.01-0.19-0.02-0.28-0.02h-0.74v1.14H258.81z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
