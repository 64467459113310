import React, { Component } from "react";
import "../styles/about.css";

export default function ThankYou(props) {
  return (
    <div>
      <div className="about-page">
        <div className="about-content">
          {props.type == "mailinglist" && "Thank you for subscribing."}
          {props.type == "contact" && (
            <p>
              Thank you.
              <br />
              Your message has been sent.
            </p>
          )}
        </div>
      </div>
      <footer>
        <div className="credits-stick">©2021 Committee Members</div>
      </footer>
    </div>
  );
}
