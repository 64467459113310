import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, useLocation } from "react-router-dom";

export default (props) => {
  let location = useLocation();

  return (
    // Pass on our props
    <Menu {...props}>
      {props.pages.map(function (page) {
        if (page == "Shop") {
          return (
            <Link
              className={
                location.pathname.includes(page) ||
                location.pathname.includes("Cart")
                  ? "bm-active"
                  : ""
              }
              onClick={() => props.stateController(false)}
              to="/Shop/All"
            >
              {page}
            </Link>
          );
        }
        if (page == "News") {
          return (
            <Link
              className={
                location.pathname.includes(page.replace(/\s/g, "")) ||
                location.pathname.includes("Article")
                  ? "bm-active"
                  : ""
              }
              onClick={() => props.stateController(false)}
              to={"/" + page.replace(/\s/g, "")}
            >
              {page}
            </Link>
          );
        }
        if (page == "Looks") {
          return (
            <Link
              className={
                location.pathname.includes(page.replace(/\s/g, ""))
                  ? "bm-active"
                  : ""
              }
              onClick={() => props.stateController(false)}
              to={
                "/" +
                page.replace(/\s/g, "") +
                "/" +
                props.firstlook.node.title.replace(/\s/g, "")
              }
            >
              {page}
            </Link>
          );
        }
        // if (page == "Universal Melody") {
        //   return (
        //     <Link
        //       to={
        //         "/" +
        //         page.replace(/\s/g, "") +
        //         "/" +
        //         props.firstum.node.title.replace(/\s/g, "")
        //       }
        //     >
        //       {page}
        //     </Link>
        //   );
        // }
        return (
          <Link
            className={
              location.pathname.includes(page.replace(/\s/g, ""))
                ? "bm-active"
                : ""
            }
            onClick={() => props.stateController(false)}
            to={"/" + page.replace(/\s/g, "")}
          >
            {page}
          </Link>
        );
      })}
      <div className="shop-sub-modals-side">
        {Object.keys(props.modals).map((m) => {
          return (
            <button
              key={m}
              onClick={() => {
                props.stateController(false);
                props.modalOpen(m);
              }}
            >
              {m}
            </button>
          );
        })}
      </div>
    </Menu>
  );
};
