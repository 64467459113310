import React, { Component } from "react";
import ThankYou from "./thankyou_page";
import "../styles/mailing.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import jsonp from "jsonp";
import queryString from "query-string";

export default class MailingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      thank_you: false,
    };
    console.log(this.props);
  }
  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };
  submitted = () => {
    var self = this;
    if (this.state.email) {
      this.subscribeToNewsLetter();
      this.setState({ thank_you: true });
      setTimeout(function () {
        self.setState({ thank_you: false, email: "" });
      }, 2000);
    }
  };

  subscribeToNewsLetter = () => {
    const formData = {
      EMAIL: this.state.email, // your email string,
    };
    jsonp(
      `https://committeemembers.us10.list-manage.com/subscribe/post-json?u=c8548a22997ad647898366a3d&amp;id=a0522ca91c&${queryString.stringify(
        formData
      )}`,
      { param: "c" },
      (err, data) => {
        console.log("err:", err);
        console.log("data:", data);
      }
    );
  };

  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          key={this.state.thank_you}
          classNames="my-node"
          timeout={200}
        >
          {this.state.thank_you ? (
            <ThankYou type="mailinglist" />
          ) : (
            <div>
              <div className="mailing-page">
                <div className="mailing-content">
                  <div
                    className="mailing-paragraph"
                    dangerouslySetInnerHTML={{ __html: this.props.page.body }}
                  ></div>
                  <label htmlFor="email">E-mail Address</label>

                  <div id="mc_embed_signup">
                    {/*<form
                    // action="https://committeemembers.us10.list-manage.com/subscribe/post?u=c8548a22997ad647898366a3d&amp;id=a0522ca91c"
                    // method="post"
                    // id="mc-embedded-subscribe-form"
                    // name="mc-embedded-subscribe-form"
                    // className="validate"
                    // target="_blank"
                    // novalidate
                    >*/}
                    <div id="mc_embed_signup_scroll">
                      <input
                        type="email"
                        value={this.state.email}
                        onChange={(e) => this.handleChange("email", e)}
                        name="EMAIL"
                        className="email"
                        id="mce-EMAIL"
                        placeholder=""
                        required
                      ></input>
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="b_c8548a22997ad647898366a3d_a0522ca91c"
                          tabindex="-1"
                          // value=""
                        ></input>
                      </div>
                      <div className="clear">
                        <button
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="mailing-button clickable-button"
                          onClick={this.submitted}
                        >
                          <p
                            style={{
                              transform: "scale(0.85, 1)",
                              transformOrigin: "bottom",
                            }}
                          >
                            Welcome to the Committee
                          </p>
                        </button>
                      </div>
                    </div>
                    {/*</form>*/}
                  </div>
                </div>
              </div>
              <div className="credits-stick">©2021 Committee Members</div>
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
