import React, { Component } from "react";
import "../styles/values.css";

export default function Values(props) {
  const extractImgs = (str) => {
    var el = document.createElement("html");
    el.innerHTML = str;
    var arr = [].slice.call(el.getElementsByTagName("img"));
    return arr.map((e) => {
      return e.src;
    });
  };
  let images = extractImgs(props.page.body);
  return (
    <div className="page">
      <div className="values-page">
        {/* <div
          className="values-content"
          dangerouslySetInnerHTML={{ __html: props.page.body }}
        /> */}
        <div className="values-content">
          {images.map((i) => {
            return <img src={i}></img>;
          })}
        </div>
      </div>
      <div className="credits-stick">©2021 Committee Members</div>
    </div>
  );
}
