import React, { Component } from "react";
import Product from "./components/Product";
import Cart from "./components/Cart";
import CustomerAuthWithMutation from "./components/CustomerAuth";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import LogoSvg from "./components/LogoSvg";

import withInstagramFeed from "origen-react-instagram-feed";
import {
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
  addVariantToCart,
  updateLineItemInCart,
  removeLineItemInCart,
} from "./checkout";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navigation from "./components/Navigation";
import ScrollToTop from "./components/scrollToTop";

import Home from "./pages/home";
import News from "./pages/news";
import Looks from "./pages/looks";
import Shop from "./pages/shop";
import About from "./pages/about";
import Values from "./pages/values";
import UniversalMelody from "./pages/universalmelody";
import Instagram from "./pages/instagram";
import Contact from "./pages/contact";
import MailingList from "./pages/mailinglist";
import Article from "./pages/article";
import NoMatch from "./pages/404";

class App extends Component {
  constructor() {
    super();
    var checkout_cache = {
      lineItems: { edges: [] },
    };
    this.state = {
      isCartOpen: false,
      isCustomerAuthOpen: false,
      isNewCustomer: false,
      products: [],
      checkout: checkout_cache,
      prevLocation: "",
    };

    this.addVariantToCart = addVariantToCart.bind(this);
    this.updateLineItemInCart = updateLineItemInCart.bind(this);
    this.removeLineItemInCart = removeLineItemInCart.bind(this);
  }

  componentWillMount() {
    this.props
      .createCheckout({
        variables: {
          input: {},
        },
      })
      .then((res) => {
        this.setState({
          checkout: res.data.checkoutCreate.checkout,
        });
        let storage = localStorage.getItem("CheckoutCache");
        if (storage) {
          storage = JSON.parse(storage);
          storage.lineItems.edges.forEach((item) => {
            this.addVariantToCart(item.node.variant.id, 1);
          });
        }
      });

    // this.addVariantToCart(this.state.id, 1);
  }

  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      shop: PropTypes.object,
      pages: PropTypes.object,
      collections: PropTypes.object,
    }).isRequired,
    createCheckout: PropTypes.func.isRequired,
    checkoutLineItemsAdd: PropTypes.func.isRequired,
    checkoutLineItemsUpdate: PropTypes.func.isRequired,
  };

  pageTitles() {
    let all_pages = [
      "News",
      "Looks",
      "Shop",
      "About",
      "Values",
      // "Universal Melody",
      "Instagram",
      "Contact",
      "Join Us",
    ];

    // Filter based on Shopify Pages
    let shopify_pages = this.props.data.pages.edges.map((x) => x.node.title);
    return all_pages.filter((n) => shopify_pages.includes(n));
  }
  getPage(title) {
    console.log(title);
    console.log(this.props.data);
    let page = this.props.data.pages.edges.filter(
      (n) => n.node.title == title
    )[0].node;
    return page;
  }
  getModals() {
    let modals = this.props.data.pages.edges.filter((n) =>
      n.node.title.includes("Modal")
    );
    return modals;
  }
  getBlog(title) {
    let blog = this.props.data.shop.blogs.edges.filter(
      (n) => n.node.title == title
    )[0].node;
    return blog;
  }
  getContent = (tag, body) => {
    var el = document.createElement("html");
    el.innerHTML = body;
    var arr = [].slice.call(el.getElementsByTagName("p"));

    for (var x = 0; x < arr.length; x++) {
      let element = arr[x];
      if (element.innerText.includes(tag)) {
        return true;
      }
    }
    return false;
  };
  checkIfWebsiteOnline() {
    return this.getContent(
      "[<WebsiteOn>]",
      this.getPage("WEBSITE SWITCH").body
    );
  }

  handleUpdate = () => {
    let { action } = this.state.location;

    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  };

  render() {
    if (this.props.data.loading) {
      return <p></p>;
    }
    if (this.props.data.error) {
      return <p>{this.props.data.error.message}</p>;
    }
    if (this.checkIfWebsiteOnline())
      return (
        <div className="App">
          <Router onUpdate={() => this.handleUpdate()}>
            <ScrollToTop />
            <Navigation
              pages={this.pageTitles()}
              collections={this.props.data.collections.edges}
              firstlook={this.getBlog("Looks").articles.edges[0]}
              firstum={this.getBlog("Universal Melody").articles.edges[0]}
              checkout={this.state.checkout}
              modals={this.getModals()}
            />
            <div id="page-wrap">
              <Route
                render={({ location }) => {
                  return (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="my-node"
                        timeout={200}
                      >
                        <Switch>
                          <Route exact path="/">
                            <Home page={this.getPage("Home")} />
                          </Route>
                          <Route path="/News">
                            <News page={this.getBlog("News")} />
                          </Route>
                          <Route path="/About">
                            <About page={this.getPage("About")} />
                          </Route>
                          <Route path="/Values">
                            <Values page={this.getPage("Values")} />
                          </Route>
                          <Route path="/Instagram">
                            <Instagram
                              media={this.props.media}
                              status={this.props.status}
                            />
                          </Route>
                          <Route path={"/UniversalMelody"}>
                            <UniversalMelody
                              page={this.getBlog("Universal Melody")}
                            />
                          </Route>
                          <Route path="/Contact">
                            <Contact />
                          </Route>
                          <Route path="/JoinUs">
                            <MailingList page={this.getPage("Join Us")} />
                          </Route>
                          {
                            // Dynamically build page per news collection
                            this.getBlog("News").articles.edges.map(
                              (article) => {
                                return (
                                  <Route
                                    path={
                                      "/Article/" +
                                      article.node.title.replace(/\s/g, "")
                                    }
                                    key={article.node.id.replace(/\s/g, "")}
                                  >
                                    <Article
                                      article={article.node}
                                      // looks={this.getBlog("Looks").articles.edges}
                                      // products={this.props.data.shop.products.edges}
                                    />
                                  </Route>
                                );
                              }
                            )
                          }
                          {
                            // Dynamically build page per looks collection
                            this.getBlog("Looks").articles.edges.map(
                              (article) => {
                                return (
                                  <Route
                                    path={
                                      "/Looks/" +
                                      article.node.title.replace(/\s/g, "")
                                    }
                                    key={article.node.id.replace(/\s/g, "")}
                                  >
                                    <Looks
                                      article={article.node}
                                      looks={
                                        this.getBlog("Looks").articles.edges
                                      }
                                      products={
                                        this.props.data.shop.products.edges
                                      }
                                    />
                                  </Route>
                                );
                              }
                            )
                          }
                          {
                            // Dynamically build page per collection
                            this.props.data.collections.edges.map(
                              (collection) => {
                                return (
                                  <Route
                                    path={
                                      "/Shop/" +
                                      collection.node.title.replace(/\s/g, "")
                                    }
                                    key={collection.node.title.replace(
                                      /\s/g,
                                      ""
                                    )}
                                  >
                                    <Shop
                                      collection={collection.node}
                                      page={this.getPage("Shop")}
                                      mailing_page={this.getPage("Join Us")}
                                      // modals={this.getModals()}
                                      // checkout={this.state.checkout}
                                    />
                                  </Route>
                                );
                              }
                            )
                          }
                          {
                            // Dynamically build page per product
                            this.props.data.shop.products.edges.map(
                              (product) => {
                                return (
                                  <Route
                                    path={
                                      "/Shop/" +
                                      product.node.id.replace(/\s/g, "")
                                    }
                                    key={product.node.id.replace(/\s/g, "")}
                                  >
                                    <Product
                                      addVariantToCart={this.addVariantToCart}
                                      checkout={this.state.checkout}
                                      key={product.node.id.toString()}
                                      product={product.node}
                                      // modals={this.getModals()}
                                    />
                                  </Route>
                                );
                              }
                            )
                          }
                          {/* Create cart page with shopify connections */}
                          <Route path="/Cart">
                            <Cart
                              removeLineItemInCart={this.removeLineItemInCart}
                              updateLineItemInCart={this.updateLineItemInCart}
                              checkout={this.state.checkout}
                            />
                          </Route>
                          {
                            // Dynamically build page per news collection
                            // this.getBlog("Universal Melody").articles.edges.map(
                            //   (universalmelody) => {
                            //     return (
                            //       <Route
                            //         path={
                            //           "/UniversalMelody/" +
                            //           universalmelody.node.title.replace(
                            //             /\s/g,
                            //             ""
                            //           )
                            //         }
                            //         key={universalmelody.node.id.replace(
                            //           /\s/g,
                            //           ""
                            //         )}
                            //       >
                            //         <UniversalMelody
                            //           article={universalmelody.node}
                            //           // looks={this.getBlog("Looks").articles.edges}
                            //           // products={this.props.data.shop.products.edges}
                            //         />
                            //       </Route>
                            //     );
                            //   }
                            // )
                          }
                          <Route path="/404" component={NoMatch} />
                          <Route component={NoMatch} />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  );
                }}
              />
            </div>
          </Router>
        </div>
      );
    else
      return (
        <>
          <MailingList page={this.getPage("Join Us")} />
          <div className="home_logo">
            <LogoSvg color={"black"} />
          </div>
        </>
      );
  }
}

const query = gql`
  query query {
    collections(first: 10) {
      edges {
        node {
          products(first: 50) {
            edges {
              node {
                id
                images(first: 10) {
                  edges {
                    node {
                      altText
                      id
                      originalSrc
                      src
                      transformedSrc
                    }
                  }
                }
                title
                priceRange {
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                  minVariantPrice {
                    currencyCode
                    amount
                  }
                }
                productType
                publishedAt
                tags
                variants(first: 10) {
                  edges {
                    node {
                      id
                      available
                    }
                  }
                }
              }
            }
          }
          title
        }
      }
    }
    shop {
      name
      description
      products(first: 50) {
        edges {
          node {
            id
            title
            options {
              id
              name
              values
            }
            variants(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  id
                  available
                  title
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    src
                  }
                  price
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                currencyCode
                amount
              }
            }
            description
            images(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  src
                }
              }
            }
            descriptionHtml
          }
        }
      }
      blogs(first: 4) {
        edges {
          node {
            id
            title
            articles(first: 10, sortKey: PUBLISHED_AT, reverse: true) {
              edges {
                node {
                  contentHtml
                  content
                  id
                  title
                  publishedAt
                  excerpt
                  excerptHtml
                  handle
                  image {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    pages(first: 15) {
      edges {
        node {
          id
          title
          bodySummary
          body
        }
      }
    }
  }
`;

const AppWithDataAndMutation = compose(
  graphql(query),
  graphql(createCheckout, { name: "createCheckout" }),
  graphql(checkoutLineItemsAdd, { name: "checkoutLineItemsAdd" }),
  graphql(checkoutLineItemsUpdate, { name: "checkoutLineItemsUpdate" }),
  graphql(checkoutLineItemsRemove, { name: "checkoutLineItemsRemove" }),
  withInstagramFeed
  // graphql(checkoutCustomerAssociate, { name: "checkoutCustomerAssociate" })
)(App);

export default AppWithDataAndMutation;
